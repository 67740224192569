<script lang="ts" setup>
const itemsAllgemein = [
  {
    label: 'Ist der Online-Service sicher und vertrauenswürdig?',
    content: 'Unsere Software wurde vom Kraftfahrtbundesamt überprüft und genehmigt, was bestätigt, dass der Service sicher und vertrauenswürdig ist. Bei weiteren Fragen stehen wir Ihnen gerne zur Verfügung.',
    ga4Id: 'trust'
  },
  {
    label: 'Welche Vorgänge können über den Online-Service abgewickelt werden?',
    slot: 'vorgaenge',
    ga4Id: 'vorgaenge'
  },
  {
    label: 'Fallen Gebühren für diesen Service an?',
    slot: 'gebuehren',
    ga4Id: 'gebuehren'
  },
  {
    label: 'Welche Zahlungsmöglichkeiten gibt es?',
    content: 'Sie haben bei uns die Wahl zwischen den am weitesten verbreiteten und vertrauenswürdigsten Zahlungsmethoden: Paypal, Kreditkarte, Kauf auf Rechnung (Klarna) und SEPA-Lastschriftmandat.',
    ga4Id: 'payment_providers'
  }
]

const itemsProzess = [
  {
    label: 'Gibt es eine Schritt-für-Schritt-Anleitung oder ein Tutorial?',
    content: 'Natürlich!  Wir haben eine leicht verständliche Schritt-für-Schritt-Anleitung entwickelt, die Sie sicher durch jeden Abschnitt des Prozesses führt. Unser Tutorial verbindet klare Anweisungen mit visuellen Hilfsmitteln, sodass Sie genau wissen, was als Nächstes zu tun ist.',
    ga4Id: 'tutorial'
  },
  {
    label: 'Erhalte ich eine Bestätigung, dass mein Fahrzeug erfolgreich angemeldet wurde?',
    slot: 'bestaetigung',
    ga4Id: 'bestaetigung'
  },
  {
    label: 'Wie lange dauert der gesamte Prozess der digitalen Zulassung?',
    slot: 'dauer',
    ga4Id: 'dauer'
  },
  {
    label: 'Welche Dokumente werden benötigt, um das Fahrzeug digital zuzulassen?',
    slot: 'dokumente',
    ga4Id: 'dokumente'
  }
]

const { umschreibung } = await usePrice()
</script>

<template lang="pug">
.text-left.pt-6.faq-section
  h3.text-3xl.font-bold.mb-3 Allgemeine Fragen
  UAccordion(
    :items="itemsAllgemein",
    color="gray",
    size="xl"
  )
    template(#gebuehren)
      div
        p.
          Ja, für unseren Online-Service zur Kfz-Zulassung Ihres Fahrzeugs fallen Gebühren an.
          Die Gesamtkosten belaufen sich auf {{ umschreibung.asString }} - und das inklusive aller Gebühren bei den Zulassungsstellen.
    
    template(#vorgaenge)
      div
        p.
          Es ist aktuell noch nicht möglich, alle Vorgänge online abzuwickeln. In Kürze werden jedoch weitere Online-Services verfügbar sein.

        UAlert.mt-4(
          title="Aktuell sind digitale Zulassungen nur auf natürliche Personen möglich (keine juristischen Personen / Unternehmen und keine Freiberufler)"
          color="cyan"
        )

        p.mt-6
          strong Diese Services sind aktuell verfügbar:

        ul
          li Umschreibung mit Halterwechsel (Fahrzeug muss aktuell auf einen anderen Fahrzeughalter angemeldet sein)
          li Außerbetriebsetzung / abmelden eines Fahrzeugs

        p.mt-6
          strong Folgende Services sind bald verfügbar:

        ul
          li Anmeldung eines Neufahrzeugs
          li Wiederzulassung eines aktuell abgemeldeten Fahrzeugs
          li Adressänderung nach einem Umzug
          li Namensänderung (z.B. nach Heirat)
          li Tageszulassung

  h3.text-3xl.font-bold.mb-3.mt-6 Fragen zum Prozess
  UAccordion(
    :items="itemsProzess"
    color="gray"
    size="xl"
  )
    template(#bestaetigung)
      p.
        Natürlich erhalten Sie eine Bestätigung, sobald Ihr Fahrzeug erfolgreich zugelassen wurde.
        Und wir machen es Ihnen so einfach wie möglich:
      
      ol
        li.
          <strong>Sofortiger Download</strong>: Unmittelbar nach der erfolgreichen Anmeldung steht
          Ihnen die vorläufige Zulassungsbescheinigung als PDF-Datei zur Verfügung. Sie können sie sofort herunterladen und ausdrucken.
          Sie muss beim Fahren mitgeführt werden.

        li.
          <strong>Email-Versand</strong>: Zusätzlich senden wir Ihnen die vorläufige Zulassungsbescheinigung umgehend per E-Mail zu.
          Damit haben Sie eine digitale Kopie, die Sie jederzeit einsehen können.

    template(#dauer)
      p.
        Wenn Sie schon alles vorbereitet haben, können Sie den Prozess in etwa 10 Minuten abschließen. Mit Vorbereitung dauert es maximal 20 Minuten.

      p.mt-2.
        Hier ein Überblick über die Zeitinvestition, die Sie erwarten können:

      ol
        li.
          <strong>Abmontieren der Kennzeichen vom Fahrzeug</strong>: Dieser Schritt sollte ebenfalls nicht mehr als 5 Minuten
          Ihrer Zeit in Anspruch nehmen.

        li.
          <strong>Freilegung der Sicherheitscodes</strong>: Das Aufdecken der verdeckten Sicherheitscodes sowohl auf dem
          Kennzeichen als auch auf den Zulassungsbescheinigungen wird voraussichtlich nur ca. 2 Minuten dauern.

        li.
          <strong>Eingabe der Daten</strong>: Ihre Dateneingabe können Sie in nur 5 Minute erledigen.

        li.
          <strong>Video-Ident & digitale Signierung</strong>: In ca. 3 Minuten können Sie sich mit unserem Partner Verimi identifizieren und die notwendigen Dokumente digital unterzeichnen.

        li.
          <strong>Automatisierte Rückmeldung</strong>: Die Bestätigung der erfolgreichen Zulassung durch die Zulassungsstelle
          erfolgt automatisiert. Es kann einige Minuten dauern, bis Sie Ihre Bestätigung herunterladen können. Wir senden Ihnen in jedem Fall
          eine E-Mail mit der Bestätigung, sobald diese verfügbar ist.


    template(#dokumente)
      p Die Dokumente, die Sie benötigen, sind:

      ol
        li Kennzeichen Vorne und Hinten
        li Fahrzeugschein bzw. Zulassungsbescheinigung Teil 1
        li Fahrzeugbrief bzw. Zulassungsbescheinigung Teil 2
        li eVB-Nummer (elektronische Versicherungsbestätigung)
        li IBAN zum Einzug der Kfz-Steuer
        li Reservierungscode für Wunschkennzeichen (falls vorhanden)

      p.mt-2
        strong Wichtige Voraussetzung:
      
      p.mt-1.
        Es ist entscheidend, dass die letzte Zulassung Ihres Fahrzeugs nach dem 01.01.2015 erfolgt ist. Ab diesem Datum wurden die neuen
        Stempelplaketten und die Zulassungsbescheinigungen Teil 1 mit einem verdeckten Sicherheitscode eingeführt, die für die
        digitale Zulassung in unserem Online-Service erforderlich sind.

</template>

<style lang="sass">
.faq-section
  span
    text-align: left

  ol
    list-style-type: decimal
    padding-left: 1rem

    li
      margin-top: 0.75rem

  ul
    list-style-type: disc
    padding-left: 1rem

    li
      margin-top: 0.75rem

  .i-heroicons-chevron-down-20-solid
    flex-shrink: 0
</style>
